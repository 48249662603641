import cloneDeep from 'lodash/cloneDeep';
import { Layouts, SeriesFields } from '@/components/ui/charts/plotly/PlotlyHelpers';
// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';
import plotlyHoverFormats from './plotly/hoverFormats';

export default {
  name: 'PerformanceTracking',

  extends: ChartMiddlewareVue,
  props: {
    performanceSettings: {
      type: Object,
      required: true,
      default: () => ({
        dataKey: String,
        dataName: String,
        unitSymbol: String,
        yConversion: String,
      }),
    },
  },
  data() {
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.PITCHER_PERFORMANCE.type,
      plotlyLayoutExtended: cloneDeep(Layouts.performanceTrackingChart),
    };
  },

  methods: {
    seriesConverter(mapped) {
      return this.convertSeries(mapped, this.unitSystem.system, undefined, undefined, [SeriesFields.Y]);
    },

    axisAdjuster() {
      let xMax = this.axisSettings.xaxis.max + 1;
      let xMin = 0;

      let yMin = 0;
      let yMax = null;

      const axisPadding = Math.abs(this.axisSettings.yaxis.max - this.axisSettings.yaxis.min) * 0.1;

      yMin = this.convertToCurrent(Math.floor(this.axisSettings.yaxis.min - axisPadding), this.yAxisConversion).value;
      yMax = this.convertToCurrent(Math.ceil(this.axisSettings.yaxis.max + axisPadding), this.yAxisConversion).value;

      this.plotlyLayoutExtended.xaxis.range = [xMin, xMax];
      this.plotlyLayoutExtended.yaxis.range = [yMin, yMax];
      // adjust number of ticks on x axis, let's say that on 1080p max 50 can be displayed
      this.plotlyLayoutExtended.xaxis.dtick = Math.ceil(Math.abs(xMax - xMin) / 40);
    },

    customMapping(converted) {
      this.plotlyData = converted.map((trace) => {
        if (!trace.hasOwnProperty('hoverinfo')) {
          trace.hoverinfo = 'none';
          trace.hovertemplate = '';
        }
        if (!trace.hasOwnProperty('type')) {
          trace.mode = 'markers';
          trace.hoverinfo = 'all';
          trace.line = { color: 'rgba(0,143,251,0.1)', width: 4 };
          trace.marker = { color: 'rgb(0, 143, 251)', width: 6 };
        }

        return trace;
      });

      if (this.plotlyData?.length && this.plotlyData[0]?.key === 'PITCH_EXTENSION') {
        this.plotlyLayoutExtended.yaxis.hoverformat = plotlyHoverFormats.ONE_DIGIT_FLOAT;
      }
    },

    updateSeries() {
      this.plotlyLayoutExtended.title.text = `${this.performanceSettings.dataName} tracking`;
      this.plotlyLayoutExtended.yaxis.title = `${this.performanceSettings.dataName}, ${this.performanceSettings.unitSymbol}`;
      this.plotlyLayoutExtended.xaxis.title = this.xAxisTitle;

      /*
      * NOTE: The change in accessing methods depends on the way the parent component is exported:
      * when using `Vue.extend` vs just export
      * @see https://github.com/vuejs/vue/issues/2977
      */

      if (typeof ChartMiddlewareVue.methods !== 'undefined') {
        ChartMiddlewareVue.methods.updateSeries.call(this);
      } else if (typeof ChartMiddlewareVue.options?.methods !== 'undefined') {
        ChartMiddlewareVue.options.methods.updateSeries.call(this);
      }
    },
  },
};
